<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h2 class="card-header-title">Informations</h2>
      </div>
      <div class="card-content">
        <table class="table is-striped is-fullwidth">
          <tr>
            <th>Synchronisé</th>
            <td>
              <b-icon
                size="is-small"
                :type="queclinkTrackerConfig.isSynchronized ? 'is-success' : 'is-danger'"
                :icon="queclinkTrackerConfig.isSynchronized ? 'check' : 'times'"
              />
            </td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{{ getStatus(queclinkTrackerConfig.syncStatus) }}</td>
          </tr>
          <tr>
            <th>Intervalle en secondes</th>
            <td>{{ queclinkTrackerConfig.interval || '–' }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="card mt-5">
      <div class="card-header">
        <h2 class="card-header-title">Modifier la configuration</h2>
      </div>
      <div class="card-content">
        <queclink-tracker-config-form
          v-if="queclinkTrackerConfig"
          :queclink-tracker-config="queclinkTrackerConfig"
          :loading="loading"
          @submit="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { QUECLINK_TRACKER_CONFIG_SYNC_STATUS } from '@constants/queclinkTrackerConfig/syncStatus'
import QueclinkTrackerConfigForm from './QueclinkTrackerConfigForm.vue'
import { mapActions } from 'vuex'

export default {
  name: 'QueclinkTrackerEndpoint',

  components: { QueclinkTrackerConfigForm },

  props: {
    queclinkTracker: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      queclinkTrackerConfig: {
        id: this.queclinkTracker.id,
        interval: null,
        syncStatus: null,
        isSynchronized: false,
      },
    }
  },

  async created() {
    await this.load()
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      this.loading = true

      try {
        this.queclinkTrackerConfig = await this.$services.queclinkTrackerConfigService.getById(
          this.queclinkTracker.id,
        )
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },

    async submit(queclinkTrackerConfig) {
      this.loading = true

      try {
        this.queclinkTrackerConfig = await this.$services.queclinkTrackerConfigService.update(
          queclinkTrackerConfig,
        )

        this.addToastMessage({
          text: 'Configuration mise à jour',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    getStatus(status) {
      const syncStatus = QUECLINK_TRACKER_CONFIG_SYNC_STATUS[status]

      return syncStatus ?? 'Aucune configuration'
    },
  },
}
</script>
