<template>
  <div class="card">
    <div class="card-header is-flex is-justify-content-space-between is-align-items-center">
      <h2 class="card-header-title">Derniers événements du endpoint</h2>
      <b-tooltip label="Recharger les événements">
        <b-button
          class="mr-2"
          type="is-primary"
          size="is-small"
          icon-left="refresh"
          :disabled="loading"
          @click="load"
        />
      </b-tooltip>
    </div>
    <div class="card-content">
      <o-table
        :items="events"
        :show-total="false"
        :draggable="false"
        :loading="loading"
        default-sort="timestamp"
        default-sort-direction="desc"
      >
        <b-table-column v-slot="{ row }" field="timestamp" label="Date" sortable>
          {{ formatDate(row.timestamp) }}
        </b-table-column>
        <b-table-column v-slot="{ row }" field="event_type.description" label="Type" sortable>
          <b-icon
            v-if="eventTypeIcon[row.event_type.id]"
            :type="eventTypeIcon[row.event_type.id].type"
            :icon="eventTypeIcon[row.event_type.id].icon"
          />
          {{ row.event_type.description }}
        </b-table-column>
        <b-table-column v-slot="{ row }" field="event_source.description" label="Source" sortable>
          {{ row.event_source.description }}
        </b-table-column>
        <b-table-column v-slot="{ row }" label="Opérateur">
          {{ row.detail?.name ? row.detail.name : '–' }}
        </b-table-column>
        <b-table-column v-slot="{ row }" label="Pays">
          {{ row.detail?.country ? row.detail.country.name : '–' }}
        </b-table-column>
      </o-table>
    </div>
  </div>
</template>

<script>
import OTable from '@components/Table.vue'
import { format } from 'date-fns'
import { mapActions } from 'vuex'

export default {
  name: 'QueclinkTrackerEndpointEvents',

  components: {
    OTable,
  },

  props: {
    emnifyEndpointId: {
      type: Number,
      required: true,
    },
    isConnected: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      events: [],
    }
  },

  created() {
    this.eventTypeIcon = {
      1: { icon: 'map-marker-alt', type: 'is-info' },
      2: { icon: 'map-marker-alt', type: 'is-info' },
      3: { icon: 'check-circle', type: 'is-success' },
      5: { icon: 'times-circle', type: 'is-danger' },
    }
  },

  async mounted() {
    await this.load()
  },

  watch: {
    async isConnected(isConnected) {
      if (isConnected) {
        await this.load()
      }
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      this.loading = true

      await this.fetchEvents()

      this.loading = false
    },

    async fetchEvents() {
      try {
        this.events = await this.$services.emnifyService.getLatestEvents(this.emnifyEndpointId)
      } catch (error) {
        console.error(error)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }
    },

    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
  },
}
</script>
