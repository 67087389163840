<template>
  <div class="queclink-tracker-endpoint">
    <b-message v-if="!queclinkTracker.emnifyEndpointId" type="is-danger">
      Aucun endpoint associé
    </b-message>
    <div v-else class="columns is-multiline">
      <div class="column is-full-mobile is-one-thirds-widescreen">
        <QueclinkTrackerEndpointInformation
          :emnify-endpoint-id="queclinkTracker.emnifyEndpointId"
          class="queclink-tracker-endpoint_information"
          @is-connected="isConnected = $event"
        />
      </div>
      <div class="column is-full-mobile is-two-thirds-widescreen">
        <QueclinkTrackerEndpointMessages
          :is-connected="isConnected"
          :emnify-endpoint-id="queclinkTracker.emnifyEndpointId"
          class="queclink-tracker-endpoint_messages"
        />
      </div>
      <div class="column is-full">
        <QueclinkTrackerEndpointEvents
          :is-connected="isConnected"
          :emnify-endpoint-id="queclinkTracker.emnifyEndpointId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import QueclinkTrackerEndpointEvents from './QueclinkTrackerEndpointEvents.vue'
import QueclinkTrackerEndpointInformation from './QueclinkTrackerEndpointInformation.vue'
import QueclinkTrackerEndpointMessages from './QueclinkTrackerEndpointMessages.vue'

export default {
  name: 'QueclinkTrackerEndpoint',
  components: {
    QueclinkTrackerEndpointEvents,
    QueclinkTrackerEndpointInformation,
    QueclinkTrackerEndpointMessages,
  },

  props: {
    queclinkTracker: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isConnected: false,
    }
  },
}
</script>

<style lang="scss">
.queclink-tracker-endpoint {
  &_information {
    height: 100%;
    min-height: 350px;
  }

  &_messages {
    height: 100%;
  }
}
</style>
