<template>
  <b-modal :active="isOpen" has-modal-card @close="closeModal">
    <div class="modal-card">
      <div class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </div>
      <div class="modal-card-body">
        <p>
          <slot />
        </p>
      </div>
      <div class="modal-card-foot">
        <button class="button" type="button" @click="closeModal">Annuler</button>
        <button class="button is-danger" type="button" @click="confirmModalAction">
          {{ submitLabel }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'OModal',

  props: {
    title: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    submitLabel: {
      type: String,
      default: 'Supprimer',
    },
  },

  methods: {
    closeModal() {
      this.$emit('closeModal')
    },

    confirmModalAction() {
      this.$emit('confirmModalAction')
      this.closeModal()
    },
  },
}
</script>
