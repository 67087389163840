<template>
  <div>
    <div class="columns">
      <div class="column is-full-mobile is-6 ml-auto">
        <queclink-tracker-search />
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child card">
          <div class="card-header">
            <h2 class="card-header-title">Informations</h2>
            <div class="my-auto mx-3">
              <b-tag :type="tags[queclinkTracker.availability].type">
                {{ tags[queclinkTracker.availability].label }}
              </b-tag>
            </div>
          </div>
          <div class="card-content">
            <table class="table is-striped is-fullwidth">
              <tr>
                <th>Numéro</th>
                <td>{{ queclinkTracker.number }}</td>
              </tr>
              <tr>
                <th>IMEI</th>
                <td>{{ queclinkTracker.imei }}</td>
              </tr>
              <tr>
                <th>Date d'achat</th>
                <td>{{ formatDate(queclinkTracker.purchasedAt) }}</td>
              </tr>
              <tr>
                <th>Locations depuis l'achat</th>
                <td>{{ lives.length }}</td>
              </tr>
              <tr>
                <th>Location sur l'année glissante</th>
                <td>{{ totalLivesSinceLastYear }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child card">
          <queclink-tracker-map :id="queclinkTracker.id" class="card" />
        </div>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child card">
          <div class="card-header">
            <h2 class="card-header-title">Changement d'état</h2>
          </div>
          <div class="card-content">
            <queclink-tracker-edit-form
              :queclink-tracker="queclinkTracker"
              @update:queclinkTracker="$emit('update:queclinkTracker', $event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child card">
          <div class="card-header">
            <h2 class="card-header-title">Lives</h2>
          </div>
          <div class="card-content">
            <queclink-tracker-live-table :lives="formattedLives" :loading="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, isWithinInterval, subYears } from 'date-fns'
import QueclinkTrackerMap from './QueclinkTrackerMap.vue'
import QueclinkTrackerSearch from './QueclinkTrackerSearch.vue'
import QueclinkTrackerEditForm from './QueclinkTrackerEditForm.vue'
import QueclinkTrackerLiveTable from './QueclinkTrackerLiveTable.vue'
import { TAGS } from '@constants/tracker/tag'

export default {
  name: 'QueclinkTrackerInformation',

  components: {
    QueclinkTrackerMap,
    QueclinkTrackerSearch,
    QueclinkTrackerEditForm,
    QueclinkTrackerLiveTable,
  },

  props: {
    queclinkTracker: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      lives: [],
      liveVehicles: [],
    }
  },

  created() {
    this.tags = TAGS
  },

  mounted() {
    this.load()
  },

  computed: {
    totalLivesSinceLastYear() {
      return this.lives.filter(
        (live) =>
          isWithinInterval(live.startedAt, {
            start: subYears(new Date(), 1),
            end: new Date(),
          }) ||
          isWithinInterval(live.endedAt, {
            start: subYears(new Date(), 1),
            end: new Date(),
          }) ||
          isWithinInterval(new Date(), {
            start: live.startedAt,
            end: live.endedAt,
          }),
      ).length
    },

    formattedLives() {
      return this.lives.map((live) => ({
        ...live,
        vehicleId: this.liveVehicles[live.id]?.id,
        vehicleNumber: this.liveVehicles[live.id]?.number,
      }))
    },
  },

  methods: {
    async load() {
      this.loading = true

      this.lives = await this.$services.liveService.getAllByQueclinkTrackerId(
        this.queclinkTracker.id,
      )
      this.liveVehicles = await this.fetchLiveVehicles()

      this.loading = false
    },

    async fetchLiveVehicles() {
      return (
        await this.$services.liveVehicleService.getAllByQueclinkTrackerId(this.queclinkTracker.id)
      ).reduce(
        (vehicles, vehicle) => ({
          ...vehicles,
          [vehicle.liveId]: { ...vehicle },
        }),
        {},
      )
    },

    formatDate(date) {
      return !date ? 'Aucune date' : format(date, 'dd/MM/yyyy')
    },
  },
}
</script>
