<template>
  <div class="card queclink-tracker-endpoint-messages">
    <div class="card-header is-flex is-justify-content-space-between is-align-items-center">
      <h2 class="card-header-title">SMS</h2>
      <b-tooltip :label="rebootLabel">
        <b-button
          :disabled="!isConnected"
          class="mr-2"
          type="is-danger"
          size="is-small"
          icon-left="power-off"
          @click="confirmReboot"
        >
          Redémarrer
        </b-button>
      </b-tooltip>
      <b-tooltip label="Recharger les sms">
        <b-button
          class="mr-2"
          type="is-primary"
          size="is-small"
          icon-left="refresh"
          :disabled="loading"
          @click="load"
        />
      </b-tooltip>
    </div>
    <div class="card-content queclink-tracker-endpoint-messages__content">
      <b-loading v-if="loading" :is-full-page="false" :active="true" />
      <div v-else-if="!messages.length" class="mt-5 has-text-centered">Aucun SMS</div>
      <div v-for="message in messages" :key="message.id">
        <div class="is-size-7 has-text-centered m-5">
          {{ format(message.submit_date) }}
        </div>
        <b-notification
          v-if="message.sms_type.id === 1"
          :closable="false"
          :type="statusColor[message.status.id]"
          class="p-4 queclink-tracker-endpoint-messages__message queclink-tracker-endpoint-messages__message--right"
        >
          {{ message.payload }}
          <div v-if="message.final_date" class="has-text-right mt-2 is-size-7">
            Reçu le {{ format(message.final_date) }}
          </div>
        </b-notification>
        <b-notification
          v-else
          :closable="false"
          class="mb-2 p-4 queclink-tracker-endpoint-messages__message queclink-tracker-endpoint-messages__message--left"
        >
          {{ message.payload }}
        </b-notification>
      </div>
    </div>
    <o-modal
      :title="confirm?.title || ''"
      :isOpen="!!confirm"
      :submitLabel="confirm?.label"
      @closeModal="confirm = null"
      @confirmModalAction="confirm?.action()"
    >
      {{ confirm?.message }}
    </o-modal>
  </div>
</template>

<script>
import { format } from 'date-fns'
import OModal from '@components/Modal.vue'
import { mapActions } from 'vuex'

export default {
  name: 'QueclinkTrackerEndpointMessages',
  components: { OModal },

  props: {
    emnifyEndpointId: {
      type: Number,
      required: true,
    },
    isConnected: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      messages: [],
      confirm: null,
    }
  },

  created() {
    this.statusColor = {
      2: 'is-light is-warning',
      3: 'is-light is-warning',
      4: 'is-light is-success',
      5: 'is-light is-danger',
      6: 'is-light is-danger',
      7: 'is-light is-danger',
    }
  },

  async mounted() {
    await this.load()
  },

  computed: {
    rebootLabel() {
      return this.isConnected ? 'Redémarrer par SMS' : 'Tracker déconnecté'
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      this.loading = true

      await this.fetchSMS()

      this.loading = false
    },

    async fetchSMS() {
      try {
        this.messages = await this.$services.emnifyService.getAllMessages(this.emnifyEndpointId)
      } catch (error) {
        console.error(error)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }
    },

    async reboot() {
      this.loading = true

      try {
        await this.$services.emnifyService.reboot(this.emnifyEndpointId)

        this.addToastMessage({
          text: 'Redémarrage en cours...',
          type: 'is-info',
        })
      } catch (error) {
        console.error(error)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false

      await this.load()
    },

    confirmReboot() {
      this.confirm = {
        title: 'Redémarrage du tracker',
        message:
          'Voulez-vous vraiment redémarrer le tracker ? Cette action chargera la configuration prédéfinie.',
        label: 'Redémarrer',
        action: this.reboot,
      }
    },

    format(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
  },
}
</script>

<style lang="scss">
.queclink-tracker-endpoint-messages {
  &__content {
    height: 350px;
    overflow-y: auto;
  }

  &__message {
    max-width: 70%;
    width: fit-content;
    word-break: break-all;
  }

  &__message--right {
    margin-left: auto;
    text-align: right;
  }
}
</style>
