<template>
  <div class="card">
    <div class="card-header is-flex is-justify-content-space-between is-align-items-center">
      <h2 class="card-header-title">Informations</h2>
      <b-tooltip label="Reset la connexion de la balise">
        <b-button
          class="mr-2"
          type="is-danger"
          size="is-small"
          icon-left="wifi"
          @click="resetConnectivity"
        >
          Reconnecter
        </b-button>
      </b-tooltip>
      <b-tooltip label="Recharger les informations">
        <b-button
          v-if="endpoint && connectivity"
          class="mr-2"
          type="is-primary"
          size="is-small"
          icon-left="refresh"
          :disabled="loading"
          @click="load"
        />
      </b-tooltip>
    </div>
    <div class="card-content">
      <b-loading v-if="loading" :is-full-page="false" :active="true" />
      <b-message v-else-if="!endpoint || !connectivity" type="is-danger">
        Information de l'opérateur introuvable
      </b-message>
      <table v-else class="table is-fullwidth">
        <tr>
          <th>Status</th>
          <td
            :class="
              endpoint.status.description === 'Enabled' ? 'has-text-success' : 'has-text-danger'
            "
          >
            {{ endpoint.status.description }}
          </td>
        </tr>
        <tr>
          <th>Connexion</th>
          <td
            :class="
              connectivity.status.description === 'ONLINE' ? 'has-text-success' : 'has-text-danger'
            "
          >
            {{ capitalize(connectivity.status.description) }}
          </td>
        </tr>
        <tr>
          <th>PDP context</th>
          <td v-if="connectivity.pdp_context">Actif {{ connectivity.pdp_context.duration }}</td>
          <td v-else>–</td>
        </tr>
        <tr>
          <th>Dernier point</th>
          <td>
            {{
              connectivity.location?.last_updated_gprs
                ? formatDate(connectivity.location.last_updated_gprs)
                : '–'
            }}
          </td>
        </tr>
        <tr>
          <th>Opérateur</th>
          <td>
            {{ connectivity.location?.operator ? connectivity.location.operator.name : '–' }}
          </td>
        </tr>
        <tr>
          <th>Pays</th>
          <td>
            {{
              connectivity.location?.operator?.country
                ? connectivity.location.operator.country.name
                : '–'
            }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { capitalize } from '@helpers/capitalize'
import { mapActions } from 'vuex'

export default {
  name: 'QueclinkTrackerEndpointInformation',

  props: {
    emnifyEndpointId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      endpoint: null,
      connectivity: null,
    }
  },

  async mounted() {
    await this.load()
  },

  watch: {
    isConnected(isConnected) {
      this.$emit('is-connected', isConnected)
    },
  },

  computed: {
    isConnected() {
      return this.connectivity?.status?.description === 'ONLINE'
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    capitalize,

    async load() {
      this.loading = true

      await Promise.all([this.fetchEndpoint(), this.fetchConnectivity()])

      this.loading = false
    },

    async fetchEndpoint() {
      try {
        this.endpoint = await this.$services.emnifyService.getEndpoint(this.emnifyEndpointId)
      } catch (error) {
        console.error(error)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }
    },

    async fetchConnectivity() {
      try {
        this.connectivity = await this.$services.emnifyService.getConnectivity(
          this.emnifyEndpointId,
        )
      } catch (error) {
        console.error(error)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }
    },

    async resetConnectivity() {
      this.loading = true

      try {
        await this.$services.emnifyService.resetConnectivity(this.emnifyEndpointId)

        this.addToastMessage({
          text: 'Connection en cours...',
          type: 'is-info',
        })
      } catch (error) {
        console.error(error)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false

      await this.load()
    },

    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
  },
}
</script>
