<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li>
        <router-link :to="{ name: 'queclinkTracker.list' }"> Balises QUECLINK </router-link>
      </li>
      <li class="is-active">
        <a href="#">{{ title }}</a>
      </li>
    </template>
    <o-tabs v-if="queclinkTracker" :labels="tabItems">
      <template #0>
        <queclink-tracker-information :queclink-tracker.sync="queclinkTracker" />
      </template>
      <template #1>
        <queclink-tracker-config :queclink-tracker="queclinkTracker" />
      </template>
      <template #2>
        <queclink-tracker-endpoint :queclink-tracker="queclinkTracker" />
      </template>
    </o-tabs>
  </page-layout>
</template>

<script>
import OTabs from '@components/Tabs.vue'
import PageLayout from '@components/PageLayout.vue'
import QueclinkTrackerConfig from './QueclinkTrackerConfig'
import QueclinkTrackerEndpoint from './QueclinkTrackerEndpoint'
import QueclinkTrackerInformation from './QueclinkTrackerInformation'

export default {
  name: 'EditQueclinkTrackerPage',

  components: {
    OTabs,
    PageLayout,
    QueclinkTrackerConfig,
    QueclinkTrackerEndpoint,
    QueclinkTrackerInformation,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      queclinkTracker: null,
    }
  },

  created() {
    this.tabItems = ['Général', 'Configuration', 'Opérateur']
  },

  watch: {
    id: {
      handler: 'load',
      immediate: true,
    },
  },

  computed: {
    title() {
      return this.queclinkTracker ? `QUECLINK ${this.queclinkTracker.number}` : '...'
    },
  },

  methods: {
    async load() {
      this.loading = true

      this.queclinkTracker = await this.$services.queclinkTrackerService.getById(this.id)

      this.loading = false
    },
  },
}
</script>
