export const QUECLINK_TRACKER_CONFIG_SYNC_STATUS = {
  'pending-status-code': 'Aucun status',
  'update-command-started': 'Mise à jour démarrée',
  'update-command-confirmed': 'Mise à jour confirmée',
  'update-command-refused': 'Mise à jour refusée',
  'update-process-refused': 'Mise à jour refusée',
  'download-started': 'Téléchargement démarré',
  'download-finished': 'Téléchargement terminé',
  'download-failed': 'Échec du téléchargement',
  'update-configuration-started': 'Installation de la configuration démarrée',
  'update-configuration-succeeded': 'Installation de la configuration terminée',
  'update-configuration-failed': "Échec d'installation de la configuration",
}
